<template>
  <div class="relative">
    <div class="relative">
      <textarea
        class="mt5 px20 pb10 w-100 bg-cl-white border-box h4 sans-serif"
        :class="{empty: value === ''}"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :ref="focus ? name : false"
        :maxlength="maxlength"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
      />
      <label>
        {{ placeholder }}
      </label>
      <span v-if="maxlength" class="characters-counter">{{ `${valueLength}/${maxlength}` }}</span>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseTextarea',
  components: {
    ValidationMessages
  },
  data () {
    return {
      iconActive: false,
      icon: 'visibility'
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    maxlength: {
      type: Number,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    remainingCharacters () {
      if (!this.value) return this.value
      return this.maxlength - this.value.length
    },
    valueLength () {
      return this.value
        ? this.value.length
        : 0
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';

  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-white: color(white);
  $color-main: color(main);
  $main-smoke: color(main-smoke);
  $color-forest-green: color(forest-green);
  $color-hover: color(tertiary, $colors-background);

  textarea {
    font: 400 0.8em 'Poppins', sans-serif;;
    &:hover,
    &:focus {
      border-color: #000;
      outline: none;
    }
    border: 1px solid;
    border-color: #D6D8DE;
    resize: none;
    background: #fff;
    padding-top: 20px;
    min-height: 100px;
  }

  label {
    color: #707070;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 20px;
    transition: 0.2s ease all;
    font-size: 0.8em;
  }
  textarea:focus ~ label,
  textarea:not(.empty) ~ label {
    top: -3px;
    background-color: white;
    padding: 0 5px;
  }
  .characters-counter {
    color: #707070;
    position: absolute;
    bottom: 13px;
    right: 8px;
    pointer-events: none;
    font-size: 10px;
    line-height: 10px;
  }
</style>
