<template>
  <component
    :is="compontentType"
    :to="redirectionLink"
    :disabled="disabled"
    class="button-outline brdr-none px40 py10 brdr-50px no-outline bg-cl-transparent no-underline light fs-medium-small button-full border-box"
    :class="{
      light : color === 'light', 'brdr-white' : color === 'black', 'light' : color === 'light',
      dark : color === 'dark', 'brdr-darkgray' : color === 'dark', 'cl-black' : color === 'dark',
      px0 : link ? true : false,
      'disabled button-disabled': disabled
    }"
  >
    <slot>{{ title }}</slot>
  </component>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'

export default {
  name: 'ButtonOutline',
  directives: { focusClean },
  props: {
    title: {
      type: String,
      default: 'Button'
    },
    color: {
      type: String,
      required: false
    },
    link: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    compontentType () {
      return this.link ? 'router-link' : 'button'
    },
    redirectionLink () {
      return this.link ? this.localizedRoute(this.link) : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$dark-border: color(secondary);
$white: color(white);
$black: color(black);

.button-outline {
  background-color: $white;
  color: $black;
  box-shadow: inset 0px 0px 0 1px black;
  font-weight: 600;
  transition: all 0.3s;
}

@media screen and (min-width: 900px) {
  .button-outline {
    min-width: 160px;
  }
}
.dark {
  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
  }
}
.light {
  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
  }
}
 .disabled {
  opacity: 0.3;
}
</style>
