<template>
  <div
    class="product-image"
    :class="{'product-image--height': basic, 'product-image--width': !basic}"
    @mouseenter="showZoom=true"
    @mouseleave="showZoom=false"
    v-on="$listeners"
  >
    <picture>
      <source
        :srcset="getThumbnailPathSmall + ' 767w'"
        media="(max-width: 767px)"
        sizes="767px"
        type="image/jpeg"
      >
      <img
        class="product-image__thumb"
        :class="{'product-image__product-carousel-thumb' : productCarouselImage}"
        :alt="alt"
        v-lazy="getThumbnailPathMediumSmall"
        width="250"
        height="250"
      >
    </picture>
    <!-- <transition name="fade"> -->
    <img
      v-if="isShowHoverImage && hoverImage.src"
      :src="hoverImage.src"
      :alt="alt"
      @load="imageLoaded('high', true)"
      @error="imageLoaded('high', false)"
      class="product-image__thumb-hover"
      :class="[{'product-image__product-carousel-thumb' : productCarouselImage}, { 'visible' : (!highQualityImageError || isOnline) && isShowHoverImage }]"
    >
    <!-- </transition> -->
  </div>
</template>

<script>
import { onlineHelper } from '@vue-storefront/core/helpers'
import config from 'config'

export default {
  props: {
    isShowHoverImage: {
      type: Boolean,
      default: false
    },
    hoverImage: {
      type: Object,
      default: () => ({
        src: '',
        loading: ''
      })
    },
    image: {
      type: String,
      default: ''
    },
    image2: {
      type: Object,
      default: () => ({
        src: '',
        loading: ''
      })
    },
    mobileImg: {
      type: Boolean,
      default: false
    },
    productCarouselImage: {
      type: Boolean,
      default: false
    },
    alt: {
      type: String,
      default: ''
    },
    microcart: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      highQualityImage: false,
      highQualityImageError: false,
      basic: true
    }
  },
  computed: {
    showPlaceholder () {
      return !this.showLowerQuality && !this.showHighQuality
    },
    showHighQuality () {
      return this.highQualityImage
    },
    isOnline () {
      return onlineHelper.isOnline
    },
    getThumbnailPathSmall () {
      return this.getThumbnail(this.image, config.products.small.width, config.products.small.height)
    },
    getThumbnailPathMediumSmall () {
      return this.getThumbnail(this.image, config.products.mediumSmall.width, config.products.mediumSmall.height)
    }
  },
  methods: {
    imageLoaded (type, success = true) {
      this[`${type}QualityImage`] = success
      this[`${type}QualityImageError`] = !success
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-image {
    position: relative;
    padding-top: 150%;
    width: 100%;
    max-width: 100%;
    height: 0;
    // mix-blend-mode: multiply;
    &__placeholder,
    &__thumb {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__thumb-hover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #faf7f2;
      &.visible {
        display: block;
      }
    }
    &__product-carousel-thumb {
      max-width: 100%;
      max-height: 100%;
    }
    &__placeholder {
      max-width: 50%;
    }
    &--height {
      .product-image__thumb {
        height: 100%;
        width: auto;
      }
    }
  }
</style>
